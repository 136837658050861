// extracted by mini-css-extract-plugin
export var addressBox = "LocationEntry-module--addressBox--GZ-61";
export var anchor = "LocationEntry-module--anchor--td-BA";
export var contactBox = "LocationEntry-module--contactBox--7cNLE";
export var content = "LocationEntry-module--content--FKtwN";
export var image_left = "LocationEntry-module--image_left--vvoZa";
export var image_right = "LocationEntry-module--image_right--V91lt";
export var text_left = "LocationEntry-module--text_left--dr2c7";
export var text_right = "LocationEntry-module--text_right--WwPEg";
export var title = "LocationEntry-module--title--SXd1U";
export var vcard = "LocationEntry-module--vcard--IfMjT";