import React from 'react';

import * as styles from './LocationEntry.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const LocationEntry = ({ data, imagePosition, t }) => {
  const { title, name, description, street, postcode, city, tel, fax, email, www, image, image_alt } = data;

  const cleanedWwwUrl = www ? www.replace('https://', '').replace('http://', '').replace('/', '') : null;

  return (
    <div style={{ position: 'relative' }}>
      <span id={`plz-${postcode}`} className={styles.anchor} />
      <div className={styles.content}>
        <div className={styles[`image_${imagePosition}`]}>
          <h3>{title}</h3>
          <GatsbyImage image={getImage(image)} alt={image_alt} />
        </div>

        <div className={styles[`text_${imagePosition}`]}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          <div className={styles.vcard}>
            <div className={styles.title}>{name}</div>
            <div className="adr">
              <div className={styles.addressBox}>
                <div className="streetAddress">{street}</div>
                <span className="postalCode">{postcode}</span>
                &nbsp;<span className="locality">{city}</span>
                <div className="country-name">Österreich</div>
              </div>
              <div className={styles.contactBox}>
                {tel && <div className="">Tel.: {tel}</div>}
                {fax && <div className="">Fax: {fax}</div>}
                {email && (
                  <div className="">
                    <a href={`mailto:${email}`}>{email}</a>
                  </div>
                )}
                {www && (
                  <div>
                    <a href={www} target="_blank">
                      {cleanedWwwUrl}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationEntry;
