import React from 'react';

import LocationEntry from './LocationEntry';

import * as styles from './Locations.module.css';

export const Locations = ({ data }) => {
  return (
    <section className={'defaultWidth'}>
      {data.edges.map(({ node }, index) => (
        <LocationEntry data={node} imagePosition={index % 2 === 0 ? 'left' : 'right'} key={node.id} />
      ))}
    </section>
  );
};
